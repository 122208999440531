import { Container, Row, Col } from 'react-bootstrap';
import { Button } from '@mantine/core';
import { Link } from 'react-router-dom';

// Images
import { check } from '../../assets/images';

const BookingSuccessful = () => {
    const machineUUID = localStorage.getItem('machineUUID');
    const isAuth = localStorage.getItem('isAuth');
    
    return <Container className='min-vh-100' fluid style={{
        backgroundColor: '#F7F7F7'
    }}>
        <Row>
            <Col xs={10} sm={8} md={6} className='d-flex flex-column align-items-start mx-auto'>
                <div className='d-flex flex-column justify-content-center align-items-center mt-5 rounded-5 bg-white pt-5 pb-4 w-100'>
                    <img className='img-fluid mb-3' src={check} alt='check' />
                    <p className='fs-18 fw-600 text-center mb-1'
                        style={{
                            color: '#101828'
                        }}
                    >
                        Booking Successful
                    </p>
                    <p className='fs-14 text-center text-secondary'>Your booking has been booked.</p>
                </div>
                <Link to={JSON.parse(isAuth.toLowerCase()) ? `/?auth=${machineUUID}` : `/?machineUUID=${machineUUID}`} className='text-decoration-none align-self-end mt-4'>
                    <Button
                        sx={{
                            backgroundColor: '#FA5000',
                            '&:hover': {
                                backgroundColor: '#FA5000'
                            }
                        }}
                        onClick={() => window?.kioskVEHome()}
                    >
                        Back to Home
                    </Button>
                </Link>
            </Col>
        </Row>
    </Container>
}

export default BookingSuccessful;
