import React from 'react';
import ReactDOM from 'react-dom';
import axios from "axios";

// bootstrap css
import 'bootstrap/dist/css/bootstrap.min.css';

import App from './App';

import reportWebVitals from './reportWebVitals';

// SCSS
import './assets/scss/index.scss';

axios.interceptors.request.use((request) => {
    if (request.url) {
        request.url = process.env.REACT_APP_APP_API_BACKEND_SERVER + request.url;
    }
    if (localStorage.getItem("machineUUID")) {
        request.headers.Auth = `${localStorage.getItem("machineUUID")}`;
    }
    return request;
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
