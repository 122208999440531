import React, { useState, useEffect } from 'react';
import { Container, Box, Text, Button, Table, Modal, Chips, Chip, Select as MantineSelect, List } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import Select from 'react-select';
import { Scrollbars } from 'react-custom-scrollbars';
import { Calendar, ChevronDown } from 'tabler-icons-react';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import moment from 'moment';
import classNames from 'classnames';

// Custom Components
import Loader from '../../components/Loader/Loader';
import PageTitle from '../../components/PageTitle/PageTitle';
import Footer from '../../components/Footer/Footer';

// Utility Service
import { PriceListing } from '../../helpers';

// API-Services
import { complexBookingService, checkAvailabilityService, checkAvailabilityService2 } from '../../service/api.service';

const Scheduler = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { facilities, uuid, currency, complexImage } = location.state;

    const [loading, setLoading] = useState(true);
    const [opened, setOpened] = useState(false);
    const [opendBookingModal, setOpendBookingModal] = useState(false);
    const [selectedTime, setSelectedTime] = useState('');
    const [checkedValue, setCheckedValue] = useState([]);
    const [bookingData, setBookingData] = useState([]);
    const [bookingStartTime, setBookingStartTime] = useState(null);
    const [bookingAmPm, setBookingAmPm] = useState('AM');
    const [duration, setDuration] = useState();
    const [listOfCourts, setListOfCourts] = useState([]);
    const [isCheckedButton, setIsCheckedButton] = useState(true);
    const [timeArray, setTimeArray] = useState([]);

    const [date, setDate] = useState(new Date());

    const [bookingDate, setBookingDate] = useState(new Date());
    const [id, setId] = useState(location?.state?.id);
    const [facilitiesTitle, setFacilitiesTitle] = useState(location?.state?.facilitiesTitle);
    const [courts, setCourts] = useState(location?.state?.courts);
    const [timeSlots, setTimeSlots] = useState(location?.state?.timeSlots);
    const [is30MinAllowed, setIs30MinAllowed] = useState(location?.state?.is30MinAllowed);
    const [timings, setTimings] = useState(location?.state?.timings);
    const [maxBookingDays, setMaxBookingDays] = useState(location?.state?.maxBookingDays);

    let sortedTimings = [];
    let minMaxTime = [];

    const timingsSS = PriceListing(timings, currency);

    const Timings = [];
    let minMaxT = '';
    if (typeof timings !== 'undefined') {
        for (let i = 0; i < timings.length; i += 1) {
            for (let j = 0; j < timings[i].prices.length; j += 1) {
                Timings.push({ ...timings[i].prices[j], days: timings[i].days });
            }
        }

        minMaxT = Timings.reduce((acc, val) => {
            acc[0] = acc[0] === undefined || val.start_time < acc[0].start_time ? val : acc[0];
            acc[1] = acc[1] === undefined || val.end_time > acc[1].end_time || val.end_time === '00:00' ? val : acc[1];
            return acc;
        }, []);
        sortedTimings = JSON.parse(JSON.stringify(Timings));
        minMaxTime = minMaxT;
    }

    const futureLimit = new Date();
    futureLimit.setDate(futureLimit.getDate() + maxBookingDays);

    /**
        * @function tdclick
        * @params event, item
        * @description
    */
    const tdclick = (event, item) => {
        setOpendBookingModal(true);
        const timeStringArray = item.split('-');
        let startTime = timeStringArray?.[0];
        const endTime = timeStringArray?.[1];
        const timeItem = { start: startTime };
        setSelectedTime(timeItem);

        if (!is30MinAllowed) {
            const split = startTime.split(':');
            const hours = split[0];
            startTime = `${hours}:00`;
            timeItem.start = startTime;
            setSelectedTime(timeItem);
        }

        // Prepend any date. Use your birthday.
        const timeString12hr = new Date(`1970-01-01T${startTime}Z`).toLocaleTimeString('en-US', {
            timeZone: 'UTC',
            hour12: true,
            hour: 'numeric',
            minute: 'numeric',
        });
        setBookingStartTime(timeString12hr);
        const [hours] = timeItem?.start.split(':');
        if (parseInt(hours, 10) > 12) {
            setBookingAmPm('PM');
        }
        event.stopPropagation();
    };


    /**
        * @function convert12to24
        * @params
        * @description
    */
    const convert12to24 = () => {
        let [hours, minutes] = bookingStartTime.split(':');
        if (hours === '12') {
            hours = '00';
        }
        if (bookingAmPm === 'PM') {
            hours = parseInt(hours, 10) + 12;
        }
        return `${hours}:${minutes}`;
    };

    /**
        * @function calculateEndTime
        * @params
        * @description used to calculate end time
    */
    const calculateEndTime = () => {
        return moment(`${bookingStartTime} ${bookingAmPm}`, "hh:mm A").add(duration, 'h').format("hh:mm A")
    };

    /**
        * @function fetchBooking
        * @params
        * @description used to fetch booking
    */
    const fetchBooking = async () => {
        setLoading(true);
        const result = await complexBookingService({ id, uuid, date: moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD') });
        if (result?.data?.success === 1) {
            setBookingData(result?.data?.data);
            const timingArray = Object.keys(result?.data?.data);
            setCourts(facilities?.find(item => item?.id === id)?.courts)
            setTimeSlots(facilities?.find(item => item?.id === id)?.timeSlots)
            setIs30MinAllowed(facilities?.find(item => item?.id === id)?.booking_window === '30.0')
            setTimings(facilities?.find(item => item?.id === id)?.timings)
            setMaxBookingDays(facilities?.find(item => item?.id === id)?.max_booking_days)
            setTimeArray(timingArray);
            setLoading(false);
        } else {
            setLoading(false);
            Swal.fire({
                title: 'Error!',
                text: result?.data?.message || result?.response?.data?.message || 'Something went wrong',
                icon: 'error',
                confirmButtonText: 'Try Again',
                confirmButtonColor: '#FA5000',
            });
        }
    };

    /**
        * @function handleCheckAvailability
        * @params
        * @description used to check availability button
    */
    const handleCheckAvailability = async () => {
        if (!bookingDate || !duration || !bookingStartTime) {
            return;
        }
        setCheckedValue([])
        const result = await checkAvailabilityService({
            sports_facility_id: id,
            date: moment(bookingDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
            start: convert12to24(),
            no_of_hours: Number(duration),
            uuid,
        });

        if (result?.data?.success === 1) {
            setListOfCourts(result?.data?.data);
            setIsCheckedButton(false);
        } else {
            Swal.fire({
                title: 'Error!',
                text: result?.data?.message || result?.response?.data?.message || 'Something went wrong',
                icon: 'error',
                confirmButtonText: 'Try Again',
                confirmButtonColor: '#FA5000',
            });
        }
    };

    /**
        * @function proceedWithBooking
        * @params
        * @description used to proceed with booking for make a booking flow
    */
    const proceedWithBooking = async () => {
        if (!checkedValue || !checkedValue.length) return;
        const result = await checkAvailabilityService2({
            sports_facility_id: id,
            date: moment(bookingDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
            start: convert12to24(),
            no_of_hours: Number(duration),
            court_nos: checkedValue,
            uuid,
        });
        if (result?.data?.success === 1) {
            navigate('/booking', {
                state: {
                    sports_facility_id: id,
                    court_nos: checkedValue,
                    date: moment(bookingDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                    start: convert12to24(),
                    no_of_hours: Number(duration),
                    total_expected_cost: result?.data?.data?.final_amount,
                    sports_name: result?.data?.data?.sports_facility?.name,
                    sports_thumb: result?.data?.data?.sports_facility?.images || [],
                    sports_end_time: calculateEndTime(),
                    sports_start_time: `${bookingStartTime}`,
                    sports_start_bookingAmPm: bookingAmPm,
                    uuid,
                    facilitiesTitle,
                    courts,
                    timeSlots,
                    id,
                    isLockedBookingValue: false,
                    booking: result?.data?.data,
                    complex: result?.data?.data?.sports_facility.sports_complex,
                    complexImage
                },
            });
        } else {
            Swal.fire({
                title: 'Error!',
                text: result?.data?.message || result?.response?.data?.message || 'Something went wrong',
                icon: 'error',
                confirmButtonText: 'Try Again',
                confirmButtonColor: '#FA5000',
            });
        }
    };

    /**
        * @function handleCheckAvailabilityForPreSelected
        * @params
        * @description used to check availabuility for schedular selected slot
    */
    const handleCheckAvailabilityForPreSelected = async () => {
        if (!bookingDate || !duration || !selectedTime) {
            return;
        }
        setCheckedValue([])
        const result = await checkAvailabilityService({
            sports_facility_id: id,
            date: moment(bookingDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
            start: selectedTime?.start,
            no_of_hours: Number(duration),
            uuid,
        });
        if (result?.data?.success === 1) {
            setListOfCourts(result?.data?.data);
            setIsCheckedButton(false);
        } else {
            Swal.fire({
                title: 'Error!',
                text: result?.data?.message || result?.response?.data?.message || 'Something went wrong',
                icon: 'error',
                confirmButtonText: 'Try Again',
                confirmButtonColor: '#FA5000',
            });
        }
    };

    /**
        * @function proceedWithBookingForPreSelectedSlot
        * @params
        * @description used to proceed with booking for schedular selected slot
    */
    const proceedWithBookingForPreSelectedSlot = async () => {
        if (!checkedValue || !checkedValue.length) {
            return;
        }
        const result = await checkAvailabilityService2({
            sports_facility_id: id,
            date: moment(bookingDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
            start: selectedTime?.start,
            no_of_hours: Number(duration),
            court_nos: checkedValue,
            uuid,
        });
        if (result?.data?.success === 1) {
            navigate('/booking', {
                state: {
                    sports_facility_id: id,
                    court_nos: checkedValue,
                    date: moment(bookingDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                    start: selectedTime?.start,
                    no_of_hours: Number(duration),
                    total_expected_cost: result?.data?.data?.final_amount,
                    sports_name: result?.data?.data?.sports_facility?.name,
                    sports_thumb: result?.data?.data?.sports_facility?.images || [],
                    sports_end_time: calculateEndTime(),
                    sports_start_time: `${bookingStartTime}`,
                    sports_start_bookingAmPm: bookingAmPm,
                    uuid,
                    facilitiesTitle,
                    courts,
                    timeSlots,
                    id,
                    isLockedBookingValue: false,
                    is30MinAllowed,
                    booking: result?.data?.data,
                    complex: result?.data?.data?.sports_facility?.sports_complex,
                    complexImage
                },
            });
        } else {
            Swal.fire({
                title: 'Error!',
                text: result?.data?.message || result?.response?.data?.message || 'Something went wrong',
                icon: 'error',
                confirmButtonText: 'Try Again',
                confirmButtonColor: '#FA5000',
            });
        }
    };

    useEffect(() => {
        fetchBooking();
        const timeoutId = setTimeout(() => 3000);
        return function cleanup() {
            clearTimeout(timeoutId);
        };
    }, [date, id]);

    return <Container fluid className='px-0 min-vh-100 d-flex flex-column' size="md">
        <PageTitle uuid={uuid} logo={complexImage} />
        <Container fluid className='px-5 w-100 mb-auto' size="md" px="md">
            {!uuid && (
                <p className='fs-40 fw-500 m-auto text-center' style={{
                    color: '#ededed'
                }}>Empty/Invalid UUID</p>
            )}
            {uuid && loading ? (
                <Loader />
            ) : (
                <>
                    <Box
                        sx={() => ({
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '15px',
                            border: '2px solid #EFEFEF',
                            background: 'white',
                            '@media (max-width: 600px)': {
                                flexDirection: 'column',
                                gap: 15
                            },
                        })}
                    >
                        {id && facilitiesTitle &&
                            <Select
                                placeholder="Select Facilities"
                                className='w-xs-100'
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        border: state.isFocused && "1px solid #FA5000",
                                        boxShadow: state.isFocused && "0px 0px 2px #FA5000",
                                        "&:hover": {
                                            border: "1px solid #FA5000",
                                            boxShadow: "0px 0px 2px #FA5000"
                                        }
                                    }),
                                    option: (styles, { isSelected }) => {
                                        return {
                                            ...styles,
                                            backgroundColor: isSelected ? '#FA5000' : 'white',
                                            color: isSelected ? 'white' : '#FA5000',
                                            "&:hover": {
                                                backgroundColor: "#FA5000",
                                                color: 'white'
                                            }
                                        }
                                    }
                                }}
                                onChange={(value) => {
                                    setId(value?.value);
                                    setFacilitiesTitle(value?.label)
                                }}
                                options={facilities?.filter(item => item.status === 1).map(item => {
                                    return {
                                        value: item?.id,
                                        label: item?.name
                                    }
                                })}
                                defaultValue={{
                                    value: id,
                                    label: facilitiesTitle
                                }}
                            />
                        }
                        <Box
                            className='flex-xs-wrap w-xs-100'
                            sx={() => ({
                                display: 'flex',
                                justifyContent: 'flex-end',
                                '@media (max-width: 600px)': {
                                    marginTop: '5px',
                                    justifyContent: 'center',
                                },
                            })}
                        >
                            <DatePicker
                                placeholder="Pick date"
                                minDate={new Date()}
                                maxDate={futureLimit}
                                value={date}
                                onChange={(e) => {
                                    setDate(e);
                                    setBookingDate(e);
                                }}
                                defaultValue={new Date()}
                                icon={<Calendar className='text-dark' size={16} />}
                                sx={() => ({
                                    '@media (max-width: 600px)': {
                                        width: '100%'
                                    },
                                    '@media (min-width: 600px)': {
                                        marginLeft: '1rem'
                                    }
                                })}
                            />
                            <Button
                                onClick={() => setOpened(true)}
                                sx={() => ({
                                    backgroundColor: '#FA5000',
                                    '&:hover': {
                                        backgroundColor: '#FA5000'
                                    },
                                    '@media (max-width: 600px)': {
                                        marginTop: '20px',
                                        justifyContent: 'center',
                                        width: '100%',
                                        marginLeft: 0
                                    },
                                    '@media (min-width: 600px)': {
                                        marginLeft: '1rem'
                                    }
                                })}
                            >
                                + Make Booking
                            </Button>
                        </Box>
                    </Box>
                    <Scrollbars
                        className='scheduler-table mb-3'
                        style={{
                            height: '700px',
                            position: 'relative',
                        }}
                    >
                        <Table
                            sx={() => ({
                                borderCollapse: 'collapse',
                                width: '100%',
                                '&.mantine-Table-root tr td:first-of-type': {
                                    position: 'sticky',
                                    width: '120px',
                                    left: 0,
                                    zIndex: 2,
                                },
                                '&.mantine-Table-root th:first-of-type': {
                                    position: 'sticky',
                                    width: '120px',
                                    left: 0,
                                    zIndex: 2,
                                },
                                '@media (max-width: 480px)': {
                                    '&.mantine-Table-root tr td:first-of-type': {
                                        width: '50px !important',
                                        padding: '7px !important',
                                        textAlign: 'center',
                                    },
                                    '&.mantine-Table-root th:first-of-type': {
                                        width: '50px !important',
                                        padding: '7px !important',
                                        textAlign: 'center',
                                    },
                                    '&.mantine-Table-root tr th': {
                                        minWidth: '50px !important',
                                        padding: '7px !important',
                                        textAlign: 'center',
                                    },
                                    '&.mantine-Table-root tr td': {
                                        minWidth: '50px !important',
                                        padding: '7px !important',
                                        textAlign: 'center',
                                    },
                                },
                            })}
                        >
                            <thead>
                                <tr>
                                    <th
                                        style={{
                                            position: 'sticky',
                                            top: 0,
                                            padding: '15px',
                                            border: '2px solid #EFEFEF',
                                            background: '#EFEFEF',
                                            color: '#121D22',
                                            zIndex: 100,
                                        }}
                                    >
                                        Court No.{' '}
                                    </th>
                                    {courts.map((courtsItem) => (
                                        <th
                                            key={courtsItem}
                                            style={{
                                                position: 'sticky',
                                                top: 0,
                                                padding: '15px',
                                                border: '2px solid #EFEFEF',
                                                background: '#EFEFEF',
                                                color: '#121D22',
                                                minWidth: '100px',
                                                textAlign: 'center',
                                                zIndex: '99',
                                            }}
                                        >
                                            {courtsItem}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {timeArray.map((item) => (
                                    <tr key={item}>
                                        <td
                                            className='fw-bold'
                                            style={{
                                                padding: '15px',
                                                border: '2px solid #EFEFEF',
                                                background: 'white',
                                                color: '#121D22',
                                                minWidth: '130px'
                                            }}
                                        >
                                            {`${item}`}
                                        </td>

                                        {courts.map((columnItem) => (
                                            <td
                                                onClick={(event) => {
                                                    if (bookingData?.[item]?.[columnItem] === true) tdclick(event, item);
                                                }}
                                                onKeyPress={() => true}
                                                aria-label="Court"
                                                key={columnItem}
                                                role="button"
                                                style={{
                                                    padding: '15px',
                                                    border: '2px solid #EFEFEF',
                                                    textAlign: 'center',
                                                    minWidth: '100px',
                                                    background: bookingData?.[item]?.[columnItem] === true ? 'white' : '#FA5000'
                                                }}
                                            />
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Scrollbars>
                </>
            )}

            <Modal
                opened={opened}
                onClose={() => {
                    setOpened(false)
                    setListOfCourts([])
                    setCheckedValue([])
                    setIsCheckedButton(true)
                    setDuration()
                }}
                closeOnClickOutside={false}
                size="lg"
                title="Book Your Slot"
                centered
                sx={() => ({
                    '.mantine-Modal-title': {
                        fontSize: 30,
                        fontWeight: 'bold'
                    },
                    '.mantine-oy9g3x': {
                        backgroundColor: '#252a3d'
                    },
                    '@media (max-width: 480px)': {
                        '.mantine-Modal-title': {
                            fontSize: 26
                        }
                    }
                })}
            >
                {/* Modal content */}
                <Box>
                    <DatePicker
                        placeholder="Pick date"
                        minDate={new Date()}
                        maxDate={futureLimit}
                        value={bookingDate}
                        onChange={(e) => {
                            setBookingDate(e);
                            setIsCheckedButton(true);
                            setCheckedValue([])
                        }}
                        defaultValue={bookingDate}
                        label="Date"
                        icon={<Calendar size={16} />}
                        sx={() => ({
                            label: {
                                fontSize: 16,
                                fontWeight: 600,
                                color: 'black'
                            }
                        })}
                    />
                    <Box
                        className='mt-3'
                        sx={() => ({
                            display: 'flex',
                            alignItems: 'center',
                            '@media (max-width: 480px)': {
                                flexDirection: 'column',
                                gap: 20,
                            },
                        })}
                    >
                        <Box
                            sx={() => ({
                                display: 'flex',
                                alignItems: 'end',
                                flex: '1 1 50%',
                                maxWidth: '70%',
                                gap: '0 15px',
                                '@media (max-width: 480px)': {
                                    alignItems: 'center',
                                    flex: '1 1 100%',
                                    maxWidth: '100%',
                                    gap: 20,
                                    flexDirection: 'column',
                                },
                            })}
                        >
                            <Box
                                sx={() => ({
                                    flex: '1 1 50%',
                                    maxWidth: '50%',
                                    '@media (max-width: 480px)': {
                                        flex: '1 1 100%',
                                        maxWidth: '100%'
                                    }
                                })}
                            >
                                <Text className='fs-16 fw-600'>Start Time</Text>
                                <MantineSelect
                                    placeholder="Select Start Time"
                                    rightSection={<ChevronDown size={22} />}
                                    rightSectionWidth={30}
                                    styles={{ rightSection: { pointerEvents: 'none' } }}
                                    onChange={(value) => {
                                        setBookingStartTime(value);
                                        setIsCheckedButton(true);
                                        setCheckedValue([])
                                    }}
                                    data={
                                        is30MinAllowed
                                            ? [
                                                { value: '01:00', label: '1:00' },
                                                { value: '01:30', label: '1:30' },
                                                { value: '02:00', label: '2:00' },
                                                { value: '02:30', label: '2:30' },
                                                { value: '03:00', label: '3:00' },
                                                { value: '03:30', label: '3:30' },
                                                { value: '04:00', label: '4:00' },
                                                { value: '04:30', label: '4:30' },
                                                { value: '05:00', label: '5:00' },
                                                { value: '05:30', label: '5:30' },
                                                { value: '06:00', label: '6:00' },
                                                { value: '06:30', label: '6:30' },
                                                { value: '07:00', label: '7:00' },
                                                { value: '07:30', label: '7:30' },
                                                { value: '08:00', label: '8:00' },
                                                { value: '08:30', label: '8:30' },
                                                { value: '09:00', label: '9:00' },
                                                { value: '09:30', label: '9:30' },
                                                { value: '10:00', label: '10:00' },
                                                { value: '10:30', label: '10:30' },
                                                { value: '11:00', label: '11:00' },
                                                { value: '11:30', label: '11:30' },
                                                { value: '12:00', label: '12:00' },
                                            ]
                                            : [
                                                { value: '01:00', label: '1:00' },
                                                { value: '02:00', label: '2:00' },
                                                { value: '03:00', label: '3:00' },
                                                { value: '04:00', label: '4:00' },
                                                { value: '05:00', label: '5:00' },
                                                { value: '06:00', label: '6:00' },
                                                { value: '07:00', label: '7:00' },
                                                { value: '08:00', label: '8:00' },
                                                { value: '09:00', label: '9:00' },
                                                { value: '10:00', label: '10:00' },
                                                { value: '11:00', label: '11:00' },
                                                { value: '12:00', label: '12:00' },
                                            ]
                                    }
                                />
                            </Box>
                            <MantineSelect
                                sx={() => ({
                                    flex: '1 1 40%',
                                    maxWidth: '40%',
                                    select: {
                                        fontSize: 16,
                                    },
                                    '@media (max-width: 480px)': {
                                        flex: '1 1 100%',
                                        maxWidth: '100%',
                                    },
                                })}
                                placeholder="Select AM/PM"
                                rightSection={<ChevronDown size={22} />}
                                rightSectionWidth={30}
                                styles={{ rightSection: { pointerEvents: 'none' } }}
                                onChange={(value) => {
                                    setBookingAmPm(value);
                                    setIsCheckedButton(true);
                                    setCheckedValue([])
                                }}
                                data={[
                                    { value: 'AM', label: 'AM' },
                                    { value: 'PM', label: 'PM' },
                                ]}
                            />
                        </Box>
                        <Box>
                            <Text className='fs-16 fw-600'>Duration</Text>
                            <MantineSelect
                                sx={() => ({
                                    select: {
                                        fontSize: 16
                                    }
                                })}
                                placeholder="Select Duration"
                                rightSection={<ChevronDown size={22} />}
                                rightSectionWidth={30}
                                styles={{ rightSection: { pointerEvents: 'none' } }}
                                onChange={(value) => {
                                    setDuration(value);
                                    setIsCheckedButton(true);
                                    setCheckedValue([])
                                }}
                                data={
                                    is30MinAllowed
                                        ? [
                                            { value: '0.5', label: '30 Mins' },
                                            { value: '1', label: '1 Hour' },
                                            { value: '1.5', label: '1.5 Hours' },
                                            { value: '2', label: '2 Hours' },
                                            { value: '2.5', label: '2.5 Hours' },
                                            { value: '3', label: '3 Hours' },
                                            { value: '3.5', label: '3.5 Hours' },
                                            { value: '4', label: '4 Hours' },
                                            { value: '4.5', label: '4.5 Hours' },
                                            { value: '5', label: '5 Hours' },
                                            { value: '5.5', label: '5.5 Hours' },
                                            { value: '6', label: '6 Hours' },
                                        ]
                                        : [
                                            { value: '1', label: '1 Hour' },
                                            { value: '2', label: '2 Hours' },
                                            { value: '3', label: '3 Hours' },
                                            { value: '4', label: '4 Hours' },
                                            { value: '5', label: '5 Hours' },
                                            { value: '6', label: '6 Hours' },
                                        ]
                                }
                            />
                        </Box>
                    </Box>
                    <p className='mt-3 fs-16 fw-600'>Available courts</p>
                    <Box
                        className={classNames({
                            'border': !isCheckedButton && listOfCourts?.filter(item => item?.available)?.length > 0,
                            'my-3 p-3': (!isCheckedButton && listOfCourts?.filter(item => item?.available)?.length > 0) || isCheckedButton
                        })}
                        sx={() => ({
                            borderRadius: !isCheckedButton && listOfCourts?.filter(item => item?.available)?.length > 0 && '15px'
                        })}
                    >
                        <Box>
                            {!isCheckedButton && (
                                listOfCourts?.filter(item => item?.available)?.length > 0 ?
                                    <Chips value={checkedValue} onChange={setCheckedValue} multiple>
                                        {listOfCourts?.filter(item => item?.available)?.map((courtsItem) => {
                                            return <Chip
                                                key={courtsItem?.court}
                                                value={courtsItem?.court}
                                                sx={() => ({
                                                    label: {
                                                        padding: '5px 18px',
                                                        background: '#FFEEE6',
                                                        borderRadius: '5px',
                                                        marginBottom: '5px',
                                                        marginRight: '5px',
                                                        minWidth: '40px',
                                                        cursor: 'pointer',
                                                        height: 40,
                                                        display: 'inline-flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        color: '#FA5000',
                                                        fontSize: 17,
                                                        fontWeight: 500,
                                                        '@media (max-width: 480px)': {
                                                            minWidth: '90px',
                                                        },
                                                    },
                                                    '.mantine-foc7wg:hover': {
                                                        background: 'rgb(180, 43, 45)',
                                                        color: '#ffffff',
                                                    },
                                                    '.mantine-jypj8n.__mantine-ref-filled': {
                                                        background: 'rgb(180, 43, 45)',
                                                        color: '#ffffff',
                                                    },
                                                    '.mantine-jypj8n.__mantine-ref-filled:hover': {
                                                        background: 'rgb(180, 43, 45)',
                                                        color: '#ffffff',
                                                    },
                                                    '.mantine - Chips - iconWrapper': {
                                                        display: 'none'
                                                    },
                                                    span: {
                                                        color: '#ffffff',
                                                    },
                                                })}
                                            >
                                                {courtsItem?.court}
                                            </Chip>
                                        })}
                                    </Chips>
                                    : <p className='fw-600 text-center'>No courts are available for the selected time. Please choose a different time or date.</p>
                            )}
                            {isCheckedButton && (
                                <Box className='text-center'>
                                    <Button
                                        sx={() => ({
                                            backgroundColor: '#FA5000',
                                            margin: '0 auto',
                                            '&:hover': {
                                                backgroundColor: '#FA5000'
                                            }
                                        })}
                                        onClick={handleCheckAvailability}
                                        disabled={!bookingDate || !duration || !bookingStartTime}
                                    >
                                        Check Available Courts
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </Box>
                    <p className='fs-16 fw-600 mb-2'>Requirements of booking</p>
                    <Box className='p-3 border rounded mb-3'>
                        {timingsSS.map((t) => (
                            <p className='fw-600 mb-2' key={t}>{t}</p>
                        ))}
                    </Box>
                    <List>
                        <List.Item>Please double check your booking details. </List.Item>
                        <List.Item>No refund or cancellation allowed after booking is made.</List.Item>
                    </List>
                    <Box className='d-flex justify-content-end my-3'>
                        <Button
                            sx={() => ({
                                backgroundColor: '#FA5000',
                                '&:hover': {
                                    backgroundColor: '#FA5000'
                                }
                            })}
                            onClick={proceedWithBooking}
                            disabled={!checkedValue || !checkedValue.length || checkedValue.length === 0}
                        >
                            Proceed
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <Modal
                opened={opendBookingModal}
                onClose={() => {
                    setOpendBookingModal(false);
                    setListOfCourts([])
                    setCheckedValue([])
                    setIsCheckedButton(true);
                    setDuration()
                }}
                closeOnClickOutside={false}
                size="lg"
                title="Book Your Slot"
                centered
                sx={() => ({
                    '.mantine-Modal-title': {
                        fontSize: 30,
                        fontWeight: 'bold'
                    },
                    '.mantine-oy9g3x': {
                        backgroundColor: '#252a3d'
                    },
                    '@media (max-width: 480px)': {
                        '.mantine-Modal-title': {
                            fontSize: 26
                        }
                    }
                })}
            >
                {/* Modal content */}
                <Box>
                    <DatePicker
                        placeholder="Pick date"
                        minDate={new Date()}
                        maxDate={futureLimit}
                        value={bookingDate}
                        onChange={(e) => {
                            setBookingDate(e);
                            setIsCheckedButton(true);
                        }}
                        defaultValue={bookingDate}
                        label="Date"
                        icon={<Calendar className='text-dark' size={16} />}
                        sx={() => ({
                            label: {
                                fontSize: 16,
                                fontWeight: 600,
                                color: 'black'
                            }
                        })}
                    />
                    <Box
                        className='mt-3'
                        sx={() => ({
                            display: 'flex',
                            alignItems: 'center',
                            '@media (max-width: 480px)': {
                                flexDirection: 'column',
                                gap: 20,
                            },
                        })}
                    >
                        <Box
                            sx={() => ({
                                display: 'flex',
                                alignItems: 'center',
                                flex: '1 1 50%',
                                maxWidth: '50%',
                                gap: '0 15px',
                                '@media (max-width: 480px)': {
                                    alignItems: 'center',
                                    flex: '1 1 100%',
                                    maxWidth: '100%',
                                    gap: 20,
                                    flexDirection: 'column',
                                },
                            })}
                        >
                            <Box
                                sx={() => ({
                                    flex: '1 1 60%',
                                    maxWidth: '50%',
                                    '@media (max-width: 480px)': {
                                        flex: '1 1 100%',
                                        maxWidth: '100%',
                                    },
                                })}
                            >
                                <Text className='fs-16 fw-600'>Start Time</Text>
                                <Text
                                    sx={() => ({
                                        fontSize: 16
                                    })}
                                >
                                    {bookingStartTime}
                                </Text>
                            </Box>
                        </Box>
                        <Box style={{ width: '60%' }}>
                            <Text className='fs-16 fw-600'>Duration</Text>
                            <MantineSelect
                                sx={() => ({
                                    select: {
                                        fontSize: 16,
                                    },
                                })}
                                placeholder="Select Duration"
                                rightSection={<ChevronDown size={22} />}
                                rightSectionWidth={30}
                                styles={{ rightSection: { pointerEvents: 'none' } }}
                                onChange={(value) => {
                                    setDuration(value);
                                    setIsCheckedButton(true);
                                    setCheckedValue([])
                                }}
                                data={
                                    is30MinAllowed
                                        ? [
                                            { value: '0.5', label: '30 Mins' },
                                            { value: '1', label: '1 Hour' },
                                            { value: '1.5', label: '1.5 Hours' },
                                            { value: '2', label: '2 Hours' },
                                            { value: '2.5', label: '2.5 Hours' },
                                            { value: '3', label: '3 Hours' },
                                            { value: '3.5', label: '3.5 Hours' },
                                            { value: '4', label: '4 Hours' },
                                            { value: '4.5', label: '4.5 Hours' },
                                            { value: '5', label: '5 Hours' },
                                            { value: '5.5', label: '5.5 Hours' },
                                            { value: '6', label: '6 Hours' },
                                        ]
                                        : [
                                            { value: '1', label: '1 Hour' },
                                            { value: '2', label: '2 Hours' },
                                            { value: '3', label: '3 Hours' },
                                            { value: '4', label: '4 Hours' },
                                            { value: '5', label: '5 Hours' },
                                            { value: '6', label: '6 Hours' },
                                        ]
                                }
                            />
                        </Box>
                    </Box>
                    <p className='mt-3 fs-16 fw-600'>Available courts</p>
                    <Box
                        className={classNames({
                            'border': !isCheckedButton && listOfCourts?.filter(item => item?.available)?.length > 0,
                            'my-3 p-3': (!isCheckedButton && listOfCourts?.filter(item => item?.available)?.length > 0) || isCheckedButton
                        })}
                        sx={() => ({
                            borderRadius: !isCheckedButton && listOfCourts?.filter(item => item?.available)?.length > 0 && '15px'
                        })}
                    >
                        <Box>
                            {!isCheckedButton && (
                                listOfCourts?.filter(item => item?.available)?.length > 0 ?
                                    <Chips value={checkedValue} onChange={setCheckedValue} multiple>
                                        {listOfCourts?.filter(item => item?.available)?.map((courtsItem) => {
                                            return <Chip
                                                key={courtsItem?.court}
                                                value={courtsItem?.court}
                                                sx={() => ({
                                                    label: {
                                                        padding: '5px 18px',
                                                        background: '#FFEEE6',
                                                        borderRadius: '5px',
                                                        marginBottom: '5px',
                                                        marginRight: '5px',
                                                        minWidth: '40px',
                                                        cursor: 'pointer',
                                                        height: 40,
                                                        display: 'inline-flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        color: '#FA5000',
                                                        fontSize: 17,
                                                        fontWeight: 500,
                                                        '@media (max-width: 480px)': {
                                                            minWidth: '90px',
                                                        },
                                                    },
                                                    '.mantine-foc7wg:hover': {
                                                        background: 'rgb(180, 43, 45)',
                                                        color: '#ffffff',
                                                    },
                                                    '.mantine-jypj8n.__mantine-ref-filled': {
                                                        background: 'rgb(180, 43, 45)',
                                                        color: '#ffffff',
                                                    },
                                                    '.mantine-jypj8n.__mantine-ref-filled:hover': {
                                                        background: 'rgb(180, 43, 45)',
                                                        color: '#ffffff',
                                                    },
                                                    '.mantine - Chips - iconWrapper': {
                                                        display: 'none'
                                                    },
                                                    span: {
                                                        color: '#ffffff',
                                                    },
                                                })}
                                            >
                                                {courtsItem?.court}
                                            </Chip>
                                        })}
                                    </Chips>
                                    : <p className='fw-600 text-center'>No courts are available for the selected time. Please choose a different time or date.</p>
                            )}

                            {isCheckedButton && (
                                <Box className='text-center'>
                                    <Button
                                        sx={() => ({
                                            backgroundColor: '#FA5000',
                                            margin: '0 auto',
                                            '&:hover': {
                                                backgroundColor: '#FA5000'
                                            }
                                        })}
                                        onClick={handleCheckAvailabilityForPreSelected}
                                        disabled={!bookingDate || !duration || !selectedTime}
                                    >
                                        Check Available Courts
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </Box>
                    <p className='fs-16 fw-600 mb-2'>Requirements of booking</p>
                    <Box className='p-3 border rounded mb-3'>
                        {timingsSS.map((t) => (
                            <p className='fw-600 mb-2'>{t}</p>
                        ))}
                    </Box>
                    <List>
                        <List.Item>Please double check your booking details. </List.Item>
                        <List.Item>No refund or cancellation allowed after booking is made.</List.Item>
                    </List>
                    <Box className='d-flex justify-content-end my-3'>
                        <Button
                            sx={() => ({
                                backgroundColor: '#FA5000',
                                '&:hover': {
                                    backgroundColor: '#FA5000'
                                }
                            })}
                            onClick={proceedWithBookingForPreSelectedSlot}
                            disabled={!checkedValue || !checkedValue.length || checkedValue.length === 0}
                        >
                            Proceed
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Container>
        <Footer />
    </Container>
};

export default Scheduler;
