import moment from 'moment';

/**
	* @function getAmountFromPercent
	* @params percent, expectedCost
	* @description
*/
export const getAmountFromPercent = (percent, expectedCost) => {
	if (!percent) return 0;
	const percentageAmount = (expectedCost * percent) / 100;
	return parseFloat(percentageAmount).toFixed(2);
}

/**
	* @function getCurrencyText
	* @params currency
	* @description
*/
export const getCurrencyText = (currency) => {
	if (currency === 1) return 'RM';
	return 'SGD';
}

/**
	* @function getFeeFromBookingAmount
	* @params fee, bookingAmount
	* @description
*/
export const getFeeFromBookingAmount = (fee, bookingAmount) => {
	return (Number(fee) / Number(bookingAmount)) * 100;
}

/**
	* @function calculateEndTime
	* @params startTime, hours
	* @description
*/
export const calculateEndTime = (startTime, hours) => {
	const endTime = moment(startTime).add('hours', hours);
	return endTime;
}

/**
	* @function pluralize
	* @params count, noun, suffix
	* @description
*/
const pluralize = (count, noun, suffix = 's') => {
	return `${count} ${noun}${Number(count) !== 1 ? suffix : ''}`;
}

/**
	* @function pricelist
	* @params price, day, currency
	* @description
*/
const pricelist = (price, day, currency) => {
	return price?.map((item) => {
		const d = moment(item?.start_time, 'HH:mm');
		return `${day} : ${item?.start_time} - ${d.add(item?.no_of_hours, 'h').format('HH:00')}${` (Price: ${currency}`}${item?.default?.H1 ? item?.default?.H1 : item?.default}/hr)${item?.min_booking_hour ? ` (min: ${pluralize(item?.min_booking_hour, 'hr')}` : ''})`;
	});
}

/**
	* @function days
	* @params item
	* @description
*/
const days = (item) => {
	const dayOfWeek = [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
		'Sunday',
		'Public Holiday',
	];

	return dayOfWeek[item];
}

/**
	* @function Pricing
	* @params timings, currency
	* @description
*/
const Pricing = (timings, currency) => {
	return timings?.map((item) => {
		const d = item?.days.length < 2 ? days(item?.days[0]) : `${days(item?.days[0])} to ${days(item?.days[item?.days?.length - 1])}`;
		return pricelist(item?.prices, d, currency);
	});
}

/**
	* @function PriceListing
	* @params timings, currency
	* @description
*/
export const PriceListing = (timings, currency) => {
	const pricingLi = Pricing(timings, currency);
	const pricenow = pricingLi.flat();
	return [...new Set(pricenow)];
}
