import { useState, useEffect } from "react";

const ProgressBar = ({ value = 0 }) => {
    const [percent, setPercent] = useState(value);

    useEffect(() => {
        if (percent < 100) {
            setTimeout(() => {
                setPercent(newval => newval + 1)
                if (percent === 99) {
                    setPercent(0)
                }
            }, 20);
        }
    }, [percent]);

    return <>
        <div className="progressbar">
            <div className="progressbarfill" style={{ 
                width: `${percent}%`
            }} />
        </div>
    </>
}

export default ProgressBar