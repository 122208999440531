import React, { useState, useEffect } from 'react';
import { Button } from '@mantine/core';
import { Container, Row, Col } from 'react-bootstrap';
import Swal from 'sweetalert2';

import { useLocation, Link } from 'react-router-dom';

// Components
import ProgressBar from '../../components/ProgressBar/ProgressBar';

const Payment = () => {
    const machineUUID = localStorage.getItem('machineUUID');
    const isAuth = localStorage.getItem('isAuth');
    
    const location = useLocation();
    const { desc, refNo, remark, price, paymentMethod, uuid, paymentUrl } = location.state;

    const [progress, setProgress] = useState(0);

    useEffect(() => {
        setInterval(() => {
            setProgress((val) => val + 1);
        }, 100);
    }, [])

    useEffect(async () => {
        if (desc && refNo && remark && price && paymentMethod && !paymentUrl) {
            try {
                window?.kioskVEMPayment(desc, refNo, remark, price, paymentMethod);
            } catch (e) {
                Swal.fire({
                    title: 'Error!',
                    text: e || 'Something went wrong',
                    icon: 'error',
                    confirmButtonText: 'Try Again',
                    confirmButtonColor: '#FA5000'
                });
            }
        } else if (paymentUrl) {
            window.open(paymentUrl, '_self');
        }
    }, [desc, refNo, remark, price, paymentMethod])

    return <Container className='min-vh-100' fluid style={{
        backgroundColor: '#F7F7F7'
    }}>
        <Row>
            <Col xs={10} sm={8} md={6} className='d-flex flex-column align-items-start mx-auto'>
                <div className='d-flex flex-column justify-content-center align-items-center mt-5 rounded-5 bg-white pt-5 pb-4 w-100'>
                    <ProgressBar value={ progress} />
                    <p className='fs-18 fw-600 text-center mt-4 mb-1'
                        style={{
                            color: '#101828'
                        }}
                    >
                        Payment is processing
                    </p>
                    <p className='fs-14 text-center text-secondary'>Please wait for the confirmation.</p>
                </div>
                <Link to={JSON.parse(isAuth.toLowerCase()) ? `/?auth=${machineUUID}` : `/?machineUUID=${machineUUID}`} className='text-decoration-none align-self-end mt-4'>
                    <Button
                        sx={{
                            backgroundColor: '#FA5000',
                            '&:hover': {
                                backgroundColor: '#FA5000'
                            }
                        }}
                        onClick={() => window?.kioskVEHome()}
                    >
                        {/* <Link to="/" state={{ machineUUID: uuid }}> */}
                            Cancel Payment
                        {/* </Link> */}
                    </Button>
                </Link>
            </Col>
        </Row>
    </Container>
}

export default Payment;
