import axios from 'axios';

/**
    * @function facilitiesListService
    * @params
    * @description used to get facilities list
*/
export const facilitiesListService = () => {
    try {
        return axios.get(`/kiosk/sports_facility`);
    } catch (error) {
        return error;
    }
}

/**
    * @function complexBookingService
    * @params id, date
    * @description
*/
export const complexBookingService = ({ id, date }) => {
    try {
        return axios.post(`/kiosk/booking`, {
            sports_facility_id: id,
            date
        });
    } catch (error) {
        return error;
    }
}

/**
    * @function checkAvailabilityService
    * @params sports_facility_id, date, start, no_of_hours
    * @description used to get court
*/
export const checkAvailabilityService = async ({ sports_facility_id, date, start, no_of_hours }) => {
    try {
        const result = await axios.post(`/kiosk/booking/get-courts`, {
            sports_facility_id,
            date,
            start,
            no_of_hours
        });
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function checkAvailabilityService2
    * @params sports_facility_id, date, start, no_of_hours, court_nos, uuid
    * @description
*/
export const checkAvailabilityService2 = async ({ sports_facility_id, date, start, no_of_hours, court_nos, uuid }) => {
    try {
        const result = await axios.post(`/kiosk/booking/check`, {
            sports_facility_id,
            date,
            start,
            no_of_hours,
            type: 'walkin',
            court_nos,
            dynamic_discount: null
        });
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function bookingLockedService
    * @params sports_facility_id, court_nos, date, start, no_of_hours, uuid
    * @description
*/
export const bookingLockedService = async ({ sports_facility_id, court_nos, date, start, no_of_hours }) => {
    try {
        const result = await axios.post(`/api/v1/sports-complex/booking/lock-slot`, {
            sports_facility_id,
            type: 'walkin',
            court_nos,
            date,
            start,
            no_of_hours,
            dynamic_discount: null
        });
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function bookingService
    * @params sports_facility_id, court_nos, date, start, no_of_hours, user_name, user_email, user_phone
    * @description
*/
export const bookingService = async ({ sports_facility_id, court_nos, date, start, no_of_hours, user_name, user_email, user_phone }) => {
    try {
        const result = await axios.post(`/kiosk/booking/add`, {
            sports_facility_id,
            type: 'walkin',
            court_nos,
            date,
            start,
            no_of_hours,
            dynamic_discount: null,
            user_name,
            user_email,
            user_phone
        });
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function paymentSuccessErrorService
    * @params booking_id, status, transaction_id
    * @description
*/
export const paymentSuccessErrorService = async ({ booking_id, status, transaction_id }) => {
    try {
        const result = await axios.post(`/api/v1/vechnology/booking`, {
            booking_id,
            status,
            transaction_id
        });
        return result;
    } catch (error) {
        return error;
    }
}
